import React, { useEffect, useState, useCallback, useRef } from "react";
import debounce from "lodash.debounce";
import tmdbApi from "../../api/tmdbApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./header.scss";
import logo from "../../assets/logo2.png";
import placeholder from "../../assets/placeholder.jpg";

const headerNav = [
  { display: <i className="bx bxs-home"></i>, path: "/", tooltip: "Home" },
  { display: <i className="bx bxs-movie"></i>, path: "/movie", tooltip: "Movies" },
  { display: <i className="bx bxs-tv"></i>, path: "/tv", tooltip: "TV Shows" },
  { display: "Genres", path: "#", tooltip: "Genres" },
  { display: "Search", path: "#", tooltip: "Search" },
  { display: <i className="bx bxs-user"></i>, path: "/signin", tooltip: "Profile" },
];

const genres = [
  { id: "action", name: "Action" },
  { id: "adventure", name: "Adventure" },
  { id: "animation", name: "Animation" },
  { id: "comedy", name: "Comedy" },
  { id: "crime", name: "Crime" },
  { id: "documentary", name: "Documentary" },
  { id: "drama", name: "Drama" },
  { id: "family", name: "Family" },
  { id: "fantasy", name: "Fantasy" },
  { id: "history", name: "History" },
  { id: "horror", name: "Horror" },
  { id: "music", name: "Music" },
  { id: "mystery", name: "Mystery" },
  { id: "romance", name: "Romance" },
  { id: "scifi", name: "Science Fiction" },
  { id: "tv-movie", name: "TV Movie" },
  { id: "thriller", name: "Thriller" },
  { id: "war", name: "War" },
  { id: "western", name: "Western" },
];

const apiKeys = [
  'AIzaSyDRqh8wcFRwEVJrmS-LynyZva0v3fnwqRQ', // First API key
  'AIzaSyCE-g4lLCmB5tYKrUXuhIbqfsDnkYp1AB8', // Second API key
  'AIzaSyD3Rwk9S7nUdmo5V7PBVQms11QEfLn-qKY'
];

let currentKeyIndex = 0;

const getNextApiKey = () => {
  const nextKey = apiKeys[currentKeyIndex];
  currentKeyIndex = (currentKeyIndex + 1) % apiKeys.length; // Rotate to the next key
  return nextKey;
};
const fetchAISuggestions = async (query, retries = 3, backoff = 300) => {
  const apiKey = getNextApiKey(); // Rotate API keys
  const url = `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=${apiKey}`;

  const requestBody = {
    contents: [
      {
        parts: [
          {
            text: `You are a movie and TV show title retrieval system. Given a description of a movie or TV show scene or keywords, your task is to identify the EXACT title. If it doesn't have enough info, don't respond. Respond with ONLY the movie or TV show title, and nothing else. Description: ${query}. if you doesnt have enough information you have to reply need more info. thats it`,
          },
        ],
      },
    ],
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      if (response.status === 429 && retries > 0) {
        // Wait for the backoff period before retrying
        await new Promise((resolve) => setTimeout(resolve, backoff));
        return fetchAISuggestions(query, retries - 1, backoff * 2);
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (
      data.candidates &&
      data.candidates[0].content.parts &&
      data.candidates[0].content.parts[0].text
    ) {
      const suggestion = data.candidates[0].content.parts[0].text.trim();
      if (suggestion.toLowerCase() !== "need more info") {
        return suggestion;
      }
    }
    return null;
  } catch (error) {
    console.error("Error fetching AI suggestions:", error);
    return null;
  }
};

const Header = () => {
  const { pathname } = useLocation();
  const headerRef = useRef(null);
  const [isGenresOpen, setIsGenresOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("movie");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const userToken = localStorage.getItem("token");
  const userPhoto = localStorage.getItem("picture");
  const [suggestions, setSuggestions] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [sceneDescription, setSceneDescription] = useState("");

  const navigate = useNavigate();
  const active = headerNav.findIndex((e) => e.path === pathname);

  useEffect(() => {
    const shrinkHeader = () => {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        headerRef.current.classList.add("shrink");
      } else {
        headerRef.current.classList.remove("shrink");
      }
    };
    window.addEventListener("scroll", shrinkHeader);
    return () => {
      window.removeEventListener("scroll", shrinkHeader);
    };
  }, []);

  const handleProfileClick = () => {
    if (userToken) {
      navigate("/profile");
    } else {
      navigate("/signin");
    }
  };

  const fetchSuggestions = useCallback(
    debounce(async (query) => {
      if (query.trim().length > 0) {
        const params = { query };

        try {
          const [movieResponse, tvResponse] = await Promise.all([
            tmdbApi.search("movie", { params }),
            tmdbApi.search("tv", { params }),
          ]);

          const combinedResults = [
            ...movieResponse.results.map((item) => ({ ...item, media_type: "movie" })),
            ...tvResponse.results.map((item) => ({ ...item, media_type: "tv" })),
          ];

          setSuggestions(combinedResults);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      } else {
        setSuggestions([]);
      }
    }, 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value); // Update the search query
    fetchSuggestions(value); // Fetch suggestions
  };

  const handleSceneDescriptionChange = async (e) => {
    const value = e.target.value;
    setSceneDescription(value); // Update the scene description

    if (value.trim().length > 0) {
      const aiSuggestion = await fetchAISuggestions(value);
      if (aiSuggestion) {
        setSearchQuery(aiSuggestion); // Populate the search bar with the AI suggestion
        fetchSuggestions(aiSuggestion); // Fetch suggestions based on the AI suggestion
      }
    }
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value); // Update search type (movie or tv)
  };

  const goToSearch = () => {
    if (keyword.trim().length > 0) {
      navigate(`/movie/search/${keyword}`);
      setSuggestions([]);
    }
  };

  useEffect(() => {
    const enterEvent = (e) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        goToSearch();
      }
    };
    document.addEventListener("keyup", enterEvent);
    return () => {
      document.removeEventListener("keyup", enterEvent);
    };
  }, [keyword, goToSearch]);

  const handleGenresClick = () => {
    setIsGenresOpen(true); // Open the modal when genres are clicked
  };

  const handleGenreSelect = (genreId) => {
    setIsGenresOpen(false); // Close the modal after selecting a genre
    navigate(`/genre/${genreId}`);
  };

  const closeModal = () => {
    setIsGenresOpen(false); // Close the modal
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/${searchType}/search/${searchQuery}`);
      setSuggestions([]); // Clear suggestions after submit
      setSearchQuery(""); // Clear input field
      closeSearchModal(); // Close the search modal
    }
  };

  const openSearchModal = () => {
    setIsSearchOpen(true); // Open the search modal
  };

  const handleSuggestionClick = (suggestion) => {
    setKeyword(suggestion.title);
    setSuggestions([]); // Clear suggestions
    setSearchQuery(""); // Clear input field
    navigate(
      suggestion.media_type === "movie"
        ? `/movie/${suggestion.id}`
        : `/tv/${suggestion.id}`
    );
    closeSearchModal(); // Close the search modal
  };

  const closeSearchModal = () => {
    setIsSearchOpen(false); // Close the search modal
  };

  return (
    <div ref={headerRef} className="header">
      <div className="header__wrap container">
        <div className="logo">
          <img src={logo} alt="FilmTv logo" />
          <Link to="/">FilmTV</Link>
        </div>

        <ul className="header__nav">
          {headerNav.map((e, i) => (
            <li key={i} className={`${i === active ? "active" : ""}`}>
              {e.display === "Genres" ? (
                <div
                  className="nav-link"
                  onClick={handleGenresClick}
                  style={{ cursor: "pointer" }}
                  title={e.tooltip}
                >
                  <i className="bx bxs-category-alt"></i>
                </div>
              ) : e.display === "Search" ? (
                <div
                  className="search-icon"
                  onClick={openSearchModal}
                  style={{ cursor: "pointer" }}
                  title={e.tooltip}
                >
                  <i className="bx bx-search"></i>
                </div>
              ) : e.path === "/signin" ? (
                userToken ? (
                  <div className="small">
                    <Link to="/profile" className="nav-link">
                      <img
                        src={
                          userPhoto ||
                          "https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg"
                        }
                        alt="Profile"
                        title={e.tooltip}
                        style={{ width: "25px", borderRadius: "50%", marginTop: "-5px" }}
                      />
                    </Link>
                  </div>
                ) : (
                  <div
                    className="small"
                    style={{ cursor: "pointer", background: "none" }}
                    onClick={handleProfileClick}
                  >
                    {e.icon && <i className={`bi-${e.icon}`}></i>} {e.display}
                  </div>
                )
              ) : (
                <Link to={e.path} className="nav-link" title={e.tooltip}>
                  {e.display}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Search Modal */}
      {isSearchOpen && (
        <div className="search-modal-overlay" onClick={closeSearchModal}>
          <div className="search-modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="search-header">
              <h3>Search</h3>
              <button onClick={closeSearchModal} className="search-close-modal">
                <i className="bx bx-x"></i>
              </button>
            </div>
            <form onSubmit={handleSearchSubmit}>
              {/* Scene Description Input */}
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
                <radialGradient id="oDvWy9qKGfkbPZViUk7TCa_eoxMN35Z6JKg_gr1" cx="-670.437" cy="617.13" r=".041" gradientTransform="matrix(128.602 652.9562 653.274 -128.6646 -316906.281 517189.719)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#1ba1e3"></stop><stop offset="0" stop-color="#1ba1e3"></stop><stop offset=".3" stop-color="#5489d6"></stop><stop offset=".545" stop-color="#9b72cb"></stop><stop offset=".825" stop-color="#d96570"></stop><stop offset="1" stop-color="#f49c46"></stop></radialGradient><path fill="url(#oDvWy9qKGfkbPZViUk7TCa_eoxMN35Z6JKg_gr1)" d="M22.882,31.557l-1.757,4.024c-0.675,1.547-2.816,1.547-3.491,0l-1.757-4.024	c-1.564-3.581-4.378-6.432-7.888-7.99l-4.836-2.147c-1.538-0.682-1.538-2.919,0-3.602l4.685-2.08	c3.601-1.598,6.465-4.554,8.002-8.258l1.78-4.288c0.66-1.591,2.859-1.591,3.52,0l1.78,4.288c1.537,3.703,4.402,6.659,8.002,8.258	l4.685,2.08c1.538,0.682,1.538,2.919,0,3.602l-4.836,2.147C27.26,25.126,24.446,27.976,22.882,31.557z"></path><radialGradient id="oDvWy9qKGfkbPZViUk7TCb_eoxMN35Z6JKg_gr2" cx="-670.437" cy="617.13" r=".041" gradientTransform="matrix(128.602 652.9562 653.274 -128.6646 -316906.281 517189.719)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#1ba1e3"></stop><stop offset="0" stop-color="#1ba1e3"></stop><stop offset=".3" stop-color="#5489d6"></stop><stop offset=".545" stop-color="#9b72cb"></stop><stop offset=".825" stop-color="#d96570"></stop><stop offset="1" stop-color="#f49c46"></stop></radialGradient><path fill="url(#oDvWy9qKGfkbPZViUk7TCb_eoxMN35Z6JKg_gr2)" d="M39.21,44.246l-0.494,1.132	c-0.362,0.829-1.51,0.829-1.871,0l-0.494-1.132c-0.881-2.019-2.467-3.627-4.447-4.506l-1.522-0.676	c-0.823-0.366-0.823-1.562,0-1.928l1.437-0.639c2.03-0.902,3.645-2.569,4.511-4.657l0.507-1.224c0.354-0.853,1.533-0.853,1.886,0	l0.507,1.224c0.866,2.088,2.481,3.755,4.511,4.657l1.437,0.639c0.823,0.366,0.823,1.562,0,1.928l-1.522,0.676	C41.677,40.619,40.091,42.227,39.21,44.246z"></path>
              </svg>
              <input
                type="text"
                placeholder="AI Search - Describe a scene..."
                value={sceneDescription}
                onChange={handleSceneDescriptionChange}
              />

              {/* Search Query Input */}
              <input
                type="text"
                placeholder="Search for movies or TV shows..."
                value={searchQuery}
                onChange={handleInputChange}
              />
              {suggestions.length > 0 && (
                <div className="suggestions">
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className="suggestion"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      <div className="show-container">
                        <img
                          src={
                            suggestion.poster_path
                              ? `https://image.tmdb.org/t/p/w200${suggestion.poster_path}`
                              : placeholder
                          }
                          alt={suggestion.title}
                        />
                        <div className="detail-container">
                          <div className="suggestion-title">
                            {suggestion.title || suggestion.original_title || suggestion.original_name}
                          </div>
                          <h6 style={{ color: "yellow" }}>
                            {suggestion.vote_average ? suggestion.vote_average.toFixed(1) : "N/A"}
                            <i className="bx bxs-star"></i>
                          </h6>
                          <div className="suggestion-overview">{suggestion.overview}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="search-type-selector">
                <select value={searchType} onChange={handleSearchTypeChange}>
                  <option value="movie">Movie</option>
                  <option value="tv">TV</option>
                </select>
              </div>
              <button className="search-submit" type="submit">
                Search
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Modal for Genre Selection */}
      {isGenresOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="genre-header">
              <h3>Select Genre</h3>
              <button onClick={closeModal} className="search-close-modal">
                <i className="bx bx-x"></i>
              </button>
            </div>
            <ul className="genre-list">
              {genres.map((genre) => (
                <li key={genre.id} onClick={() => handleGenreSelect(genre.id)}>
                  {genre.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;